import IconSelect from '../audi-ui-components/icons/Select';
import cn from 'classnames';

export const paintMap = {
  "0E0E": "Mythos Black | Metallic",
  "2D2D": "Navarra Blue | Metallic",
  "2L2L": "Typhoon Grey | Metallic",
  "2Y2Y": "Glacier White | Metallic",
  "5Y5Y": "Geyser Blue | Metallic",
  "C2C2": "Pebble Grey",
  "J6J6": "Aurora Violet | Metallic",
  "L5L5": "Floret Silver | Metallic",
  "AO": "Black leatherette, standard seats",
  "BH": "Beige leatherette, standard seats",
  "EI": "Black leatherette, Sport front seats"
};

const PaintPicker = ({ extColour, interior, extColours, intOptions = [], onChangeExt, onChangeInt }) => {
  return (
    <div className="paint-picker">
      <div className="row mb-1 d-medium-none">
        <div className="col">Colour</div>
        <div className="col text-end">Interior</div>
      </div>
      <div className="row">
        <div className="col">
          {Object.keys(extColours).map((opt,i) => (
            <button key={opt} type="button" onClick={()=>{ onChangeExt(opt); }} className={cn("paint-swatch", {"selected": extColour === opt})} style={{backgroundImage: `url("${process.env.PUBLIC_PATH}assets/exterior-swatches/${opt}.png")`}}>
              <span className="visually-hidden">{extColours[opt].name}</span>
              {extColour === opt && <span className="paint-swatch__select"><IconSelect small /></span>}
            </button>
          ))}
        </div>
        <div className="col-auto text-end">
          {intOptions.map((opt,i) => (
            <button key={opt} type="button" onClick={()=>{ onChangeInt(opt); }} className={cn("paint-swatch", {"selected": interior === opt})} style={{backgroundImage: `url("${process.env.PUBLIC_PATH}assets/interior-swatches/${opt}.jpg")`}}>
              <span className="visually-hidden">{paintMap[opt]}</span>
              {interior === opt && <span className="paint-swatch__select"><IconSelect small /></span>}
            </button>
          ))}
        </div>
      </div>
      <div className="row mt-3 mb-4 mb-medium-0">
        <div className="col-12 col-medium-6">
          <p className="aui-headline-4 mb-2 d-none d-medium-block"><b>Colour</b></p>
          <p className="">{extColours[extColour].name}</p>
          <p>{extColours[extColour].priceLCT > 0 && <span>price: ${extColours[extColour].priceLCT} (inc. GST &amp; LCT)</span>}</p>
          {extColours[extColour].extra?.desc && <p className="aui-small">{extColours[extColour].extra.desc}</p>}
        </div>
        <div className="col-6 text-end d-none d-medium-block">
          <p className="aui-headline-4 mb-2"><b>Interior</b></p>
          <p className="">{paintMap[interior]}</p>
        </div>
      </div>
    </div>
  )
}

export default PaintPicker;
