import { useState, useEffect } from "react";
import Button from '../audi-ui-components/Button';
import Accordion from 'react-bootstrap/Accordion';

const BREAKPOINT = 900;

const StandardEquipment = ({ config, modelName, modelCode, isMobile, isIpad }) => {
  
  if (isMobile) {
    return (
      <Accordion flush>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <p className="aui-headline-4"><b>Standard equipment</b></p>
          </Accordion.Header>
          <Accordion.Body className="px-0 py-3">
            <p>The Audi {modelName} comes standard with an exceptional range of luxury, performance, and technologically advanced features.</p>
            {!isIpad && config.brochure && <p>
              <Button variant="text" className="pb-medium-0" href={config.brochure} rel="nofollow"><b>Download the brochure</b></Button>
            </p>}
            {!isIpad && config.specGuide && <p>
              <Button variant="text" className="pb-medium-0" href={config.specGuide} rel="nofollow"><b>Download the technical specification guide</b></Button>
            </p>}
            <div className="mt-3">
              {config.stndEquipment[modelCode]?.layoutMobile.map((col,i) => {
                return (
                  <div key={`col${i}`}>
                    {col.map((listName,ii) => {
                      return (
                        <div key={`list${ii}`} className="mb-3">
                          <p className="aui-headline-4"><b>{listName}</b></p>
                          <ul className="equipment-list">
                            {config.stndEquipment[modelCode][listName].map((item, iii) => <li key={`li${iii}`}>{item}</li>)}
                          </ul>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  }
  
  return (
    <div className="section d-medium-flex mb-5">
      <div className="sidebar"><p className="aui-headline-4"><b>Standard equipment</b></p></div>
      <div className="section__main ps-medium-5">
        <p>The Audi {modelName} comes standard with an exceptional range of luxury, performance, and technologically advanced features.</p>
        {!isIpad && config.brochure && <p>
          <Button variant="text" className="pb-medium-0" href={config.brochure} target="_blank" rel="nofollow"><b>Download the brochure</b></Button>
        </p>}
        {!isIpad && config.specGuide && <p>
          <Button variant="text" className="pb-medium-0" href={config.specGuide} target="_blank" rel="nofollow"><b>Download the Specification Guide for full details</b></Button>
        </p>}
        <hr className="my-5 d-none d-medium-block" />
        <div className="row">
          {config.stndEquipment[modelCode]?.layoutDesktop.map((col,i) => {
            let colWidth = 12 / config.stndEquipment[modelCode].layoutDesktop.length;
            return (
              <div key={`col${i}`} className={`col-medium-${colWidth}`}>
                {col.map((listName,ii) => {
                  return (
                    <div key={`list${ii}`} className="mb-3">
                      <p className="aui-headline-4"><b>{listName}</b></p>
                      <ul className="equipment-list">
                        {config.stndEquipment[modelCode][listName].map((item, iii) => <li key={`li${iii}`}>{item}</li>)}
                      </ul>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default StandardEquipment;
