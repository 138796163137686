import { useState, useEffect, useRef } from 'react';
import API from '../functions/api';
import { formatPostcode } from '../functions/textFormatting';
import TextField from '../audi-ui-components/TextField';
import Button from '../audi-ui-components/Button';
import IconPoiOnMap from '../audi-ui-components/icons/PoiOnMap';
import IconCancel from '../audi-ui-components/icons/Cancel';
import cn from 'classnames';
import _findIndex from 'lodash/findIndex';

const DealerByPostcodeModule = ({
  postcodeName = "postcode",
  isEtron,
  values, errors, touched, setValues, setFieldValue, setFieldTouched, submitCount,
  formName
}) => {
  
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState(null);
  const [fetchDealersError, setFetchDealersError] = useState(null);
  
  useEffect(()=>{
    // console.log("postcode changed");
    if (values && values[postcodeName] && /^\d{4}$/.test(values[postcodeName])) {
      getDealerOpts(values[postcodeName]);
    }
  }, [values[postcodeName]]);
  
  const getDealerOpts = (postcode) => {
    // console.log("getDealerOpts");
    setFetchDealersError(null);
    setIsLoading(true);
    let etronFilter = isEtron ? " and etronDealer eq true" : "";
    let variantQuery = values.variantId ? `&variant=${values.variantId}` : values.variantIds ? `&variantIds=${values.variantIds.join(",")}` : "";
    let formNameQuery = formName ? `&formName=${formName}` : "";
    API.get(
      `${process.env.RAZZLE_API_MYAUDI}/1/services/locations/postcode/${postcode}?$filter=(type eq 'Sales'${etronFilter})${variantQuery}${formNameQuery}`
    ).then(response => {
        // console.log(response);
        if (response.data?.length) {
          setOptions(response.data);
          if (values.dealerId) {
            let i = _findIndex(response.data, {dealerId: values.dealerId});
            // console.log("check dealer", i);
            if (i === -1) {
              setFieldValue("dealerId", "");
              setFieldValue("dealer", null, false);
            }
          }
        } else {
          //
        }
        setIsLoading(false);
    }).catch((err) => {
        let msg = "There was a problem getting the list of dealers.";
        if (err.response?.status === 404) {
          msg = "Sorry, we couldn't find any dealers for that postcode.";
        } else if (isEtron || values.variantId) {
          msg = "We couldn't find any dealers for that postcode who sell your selected model.";
        }
        setFetchDealersError(msg);
        setIsLoading(false);
    });
  }
  
  return (
    <div className={cn("dealer-by-postcode my-4", {"is-invalid": errors.dealerId})}>
      <p>Preferred dealer*</p>
      {!(values[postcodeName] && /^\d{4}$/.test(values[postcodeName])) && <p className="aui-small">Enter your postcode to find your nearest dealers.</p>}
      {isLoading && <p className="aui-small">Finding dealers...</p>}
      {!isLoading && fetchDealersError && <p className="aui-color-text-red">{fetchDealersError}</p>}
      {options && options.map((opt,i) => {
        let hasSelected = Boolean(values.dealerId);
        let isSelected = hasSelected && values.dealerId === opt.dealerId;
        return (
          <div onClick={()=>{
            setFieldValue("dealerId", opt.dealerId);
            setFieldValue("dealer", opt, false);
          }} className={cn("dealer", {"aui-color-gray95": !isSelected, "aui-color-black aui-color-text-light selected": isSelected, "not-selected": hasSelected && !isSelected})} key={`opt${i}`}>
            <div className="px-3 py-2">
              <div className="row flex-nowrap">
                <div className="col-auto"><IconPoiOnMap small /></div>
                <div className="col d-flex align-items-start justify-content-center flex-column">
                  <span className="aui-headline-5 d-block"><b>{opt.dealerName}</b></span>
                  {isSelected && <b className="aui-small">{opt.suburb} {opt.state}</b>}
                </div>
                {isSelected && <div className="col-auto">
                  <Button variant="icon" theme="light" icon={<IconCancel large />} onClick={()=>{ setFieldValue("dealerId", ""); setFieldValue("dealer", null, false); }} />
                </div>}
              </div>
            </div>
          </div>
        );
      })}
      {errors.dealerId && !errors[postcodeName] && !fetchDealersError && (touched.dealerId || submitCount > 0) && <p className="aui-textfield__error">{errors.dealerId}</p>}
    </div>
  );
}

export default DealerByPostcodeModule;
