import Spinner from '../audi-ui-components/Spinner';
import FormField from './FormField';
import { PriceDropdown, PriceModal } from './PriceTable';
import { formatPostcode } from '../functions/textFormatting';

const getStateFromPostcode = (postcode) => {
  // https://en.wikipedia.org/wiki/Postcodes_in_Australia
  let _p = parseInt(postcode);
  if ((_p >= 1000 && _p <= 2599) || (_p >= 2619 && _p <= 2899) || (_p >= 2921 && _p <= 2999)) { return "NSW"; }
  if ((_p >= 200 && _p <= 299) || (_p >= 2600 && _p <= 2618) || (_p >= 2900 && _p <= 2920)) { return "ACT"; }
  if ((_p >= 3000 && _p <= 3999) || (_p >= 8000 && _p <= 8999)) { return "VIC"; }
  if ((_p >= 4000 && _p <= 4999) || (_p >= 9000 && _p <= 9999)) { return "QLD"; }
  if (_p >= 5000 && _p <= 5999) { return "SA"; }
  if (_p >= 6000 && _p <= 6999) { return "WA"; }
  if (_p >= 7000 && _p <= 7999) { return "TAS"; }
  if (_p >= 800 && _p <= 999) { return "NT"; }
  return null;
}

const PostcodePrice = ({ formikProps, getPrice, prices, isFetchingPrices, useDropdown, isOnlyPostcode }) => {
  
  const onPCChange = (v) => {
    if (/^\d{4}$/.test(v)) {
      let s = getStateFromPostcode(v);
      if (s && s !== null) {
        formikProps.setFieldValue("state", s);
        getPrice(Object.assign({}, formikProps.values, {state: s, postcode: v}));
      } else {
        formikProps.setFieldError("postcode", "That postcode does not match any Australian states.")
      }
    } else {
      formikProps.setFieldValue("state", null);
    }
  };
  
  if (isOnlyPostcode) {
    return (
      <FormField name="postcode"
        label="Postcode*"
        formikProps={formikProps}
        formatValue={formatPostcode}
        onChange={onPCChange}
      />
    );
  }
  
  return (
    <div className="position-relative postcode-price">
      {!prices && <FormField name="postcode"
        label="Enter postcode*"
        floatingLabel={false}
        formikProps={formikProps}
        formatValue={formatPostcode}
        onChange={onPCChange}
        wrapperClassName="mb-0"
      />}
      {prices && useDropdown && <PriceDropdown prices={prices} state={formikProps.values.state} />}
      {prices && !useDropdown && <PriceModal prices={prices} state={formikProps.values.state} />}
      {isFetchingPrices && <div className="spinner-wrapper"><Spinner isContinuous /></div>}
    </div>
  );
}

export default PostcodePrice;
