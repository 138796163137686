
export function formatMobile(value) {
    let _v = value.replace(/\D/g,'').substring(0,10); // First ten digits of input only
    let zip = _v.substring(0,4);
    let middle = _v.substring(4,7);
    let last = _v.substring(7,10);
    if(_v.length > 7) {
        return `${zip} ${middle} ${last}`;
    }
    else if(_v.length > 4) {
        return `${zip} ${middle}`;
    }
    else if(_v.length > 0) {
        return `${zip}`;
    }
    else {
        return '';
    }
}

export function formatPostcode(value) {
    return value.replace(/\D/g,'').substring(0,4);
}
