import Button from '../audi-ui-components/Button';
import IconSUV from './IconSUV';
import IconSB from './IconSB';
import Icon55Quattro from './Icon55Quattro';
import Icon45Rwd from './Icon45Rwd';
import _pull from 'lodash/pull';

const iconMap = {
  "SB": (<IconSB />),
  "SUV": (<IconSUV />),
  "RWD": (<Icon45Rwd />),
  "QUATTRO": (<Icon55Quattro />)
};

const ExteriorOptions = ({ variants, driveTypes, options, formikProps, getPrice, onChangeModel }) => {
  
  return (
    <>
    <div className="row model-section">
      <div className="col-6 col-medium-12">
        <p className="aui-headline-4 mb-3"><b>Body style</b></p>
        <div className="optrow mb-4">
          {variants.map((variant,i) => {
            let isSelected = formikProps.values.variant === variant.code;
            return (
              <Button key={`v${i}`} variant="secondary" className={`optbtn ${isSelected ? "selected" : ""}`} onClick={()=>{
                if (!isSelected) {
                  formikProps.setFieldValue("variant", variant.code);
                  onChangeModel(Object.assign({}, formikProps.values, {variant: variant.code}), formikProps.setFieldValue);
                  window.dataLayer.push({
                    'event': 'Q4 Reservation',
                    'eventAction': 'Vehicle Configurator',
                    'eventLabel': 'Body Style Selected',
                    'bodyStyle': variant.name
                  });
                }
              }}>
                {variant.icon && iconMap[variant.icon]}
                {variant.icon && <br />}
                {variant.name}
              </Button>
            );
          })}
        </div>
      </div>
      <div className="col-6 col-medium-12">
        <p className="aui-headline-4 mb-3"><b>Drive type</b></p>
        <div className="optrow mb-4">
          {driveTypes.map((dt,i) => {
            let isSelected = formikProps.values.driveType === dt.code;
            return (
              <Button key={`dt${i}`} variant="secondary" className={`optbtn ${isSelected ? "selected" : ""}`} onClick={()=>{
                if (!isSelected) {
                  formikProps.setFieldValue("driveType", dt.code);
                  onChangeModel(Object.assign({}, formikProps.values, {driveType: dt.code}), formikProps.setFieldValue);
                  window.dataLayer.push({
                    'event': 'Q4 Reservation',
                    'eventAction': 'Vehicle Configurator',
                    'eventLabel': 'Drive Type Selected',
                    'driveType': dt.name
                  });
                }
              }}>
                {dt.icon && iconMap[dt.icon]}
                {dt.icon && <br />}
                {dt.name}
              </Button>
            );
          })}
        </div>
      </div>
    </div>
    <div className="mb-4">
      <p className="aui-headline-4 mb-3"><b>Options</b></p>
      <div className="optrow mb-1">
        {options[formikProps.values.variant + formikProps.values.driveType].map((optCode,i) => {
          let isSelected = formikProps.values.options?.indexOf(optCode) > -1;
          return (
            <Button key={`opt${i}`} variant="secondary" className={`optbtn ${isSelected ? "selected" : ""}`} onClick={()=>{
              let newOptions = [...formikProps.values.options] || [];
              if (isSelected) {
                _pull(newOptions, optCode);
              } else {
                newOptions.push(optCode);
                window.dataLayer.push({
                  'event': 'Q4 Reservation',
                  'eventAction': 'Vehicle Configurator',
                  'eventLabel': 'Package Selected',
                  'packages': options[optCode]?.name
                });
              }
              formikProps.setFieldValue("options", newOptions);
              if (formikProps.values.state) {
                getPrice(Object.assign({}, formikProps.values, {options: newOptions}));
              }
            }}>
              {options[optCode]?.name}
            </Button>
          );
        })}
      </div>
      {formikProps.values.options?.map((optCode) => {
        let p = typeof options[optCode].priceLCT === "number" ? options[optCode].priceLCT : options[optCode].priceLCT[formikProps.values.variant + formikProps.values.driveType];
        return (
          <p className="mb-2 equipment-desc" key={optCode.replace(",","")}>{options[optCode].description}<br />Price: ${p} (inc. GST & LCT)</p>
        );
      })}
    </div>
    </>
  );
};

export default ExteriorOptions;
