import validate from 'validate.js';

validate.validators.truthy = function(value, options, key, attributes) {
    if (!value) {
        return options.message || "This field is required";
    }
    return;
}

const validationObj = {
    firstName: {
        presence: {
            message: "Please enter your name",
            allowEmpty: false
        }
    },
    lastName: {
        presence: {
            message: "Please enter your name",
            allowEmpty: false
        }
    },
    email: {
        presence: {
            message: "Email address is required",
            allowEmpty: false
        },
        email: {message: "Please enter a valid email address"}
    },
    mobile: {
        presence: {
            message: "Email address is required",
            allowEmpty: false
        },
        format: {
            pattern: /^04.*$/,
            message: "Please enter a valid mobile number"
        }
    },
    postcode: {
        presence: {
            message: "Please enter a postcode",
            allowEmpty: false
        },
        format: {
            pattern: /^\d{4}$/,
            message: "Please enter a valid postcode"
        }
    },
    dealerId: {
        presence: {
            message: "Please select a dealer",
            allowEmpty: false
        }
    },
    contactRegardingModel: {
      truthy: {
        message: "This field is required"
      }
    }
}

export {
    validate,
    validationObj
};
