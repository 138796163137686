import { useState, useEffect } from "react";
import Button from '../audi-ui-components/Button';
import Accordion from 'react-bootstrap/Accordion';

const BREAKPOINT = 900;

const Section = ({ children, title, isMobile }) => {
  
  if (isMobile) {
    return (
      <Accordion flush>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <p className="aui-headline-4"><b>{title}</b></p>
          </Accordion.Header>
          <Accordion.Body className="px-0 py-3">
            {children}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  }
  
  return (
    <div className="section d-medium-flex mb-5">
      <div className="sidebar"><p className="aui-headline-4"><b>{title}</b></p></div>
      <div className="section__main ps-medium-5">
        {children}
      </div>
    </div>
  );
}

export default Section;
