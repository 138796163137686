import { useState } from "react";
import Accordion from 'react-bootstrap/Accordion';
import Modal from '../audi-ui-components/Modal';
import Button from '../audi-ui-components/Button';

const sumPrices = (prices) => {
  let total = 0;
  for (let i=0; i<prices.length; i++) {
    let price = prices[i] || "0";
    total += Number(price.replace(/[^0-9.-]+/g,""));
  }
  var p = total.toFixed(2).split(".");
  var n = p[0].split("").reverse().reduce(function(acc, _num, i, orig) {
      return  _num === "-" ? acc : _num + (i && !(i % 3) ? "," : "") + acc;
  }, "") + "." + p[1];
  return n;
}

const PriceTable = ({ prices, state, showMRDP }) => {
  return (
    <table className="price-table">
      <tbody>
        <tr>
          <td>Manufacturer's List Price (Inc. GST)<sup>2</sup></td>
          <td>{prices.MLP}</td>
        </tr>
        <tr>
          <td>Options Price (Inc. GST)</td>
          <td>{prices.OptionsPrice}</td>
        </tr>
        <tr>
          <td>Recommended Dealer Delivery (Inc. GST)<sup>3</sup></td>
          <td>{prices.DealerDeliveryFee}</td>
        </tr>
        <tr className="sep">
          <td><b>Vehicle Subtotal (Inc. GST)</b></td>
          <td><b>${sumPrices([prices.MLP, prices.OptionsPrice, prices.DealerDeliveryFee])}</b></td>
        </tr>
        <tr className="sep">
          <td>Federal Govt Luxury Car Tax (LCT)</td>
          <td>{prices.LCT}</td>
        </tr>
        <tr>
          <td>{state} State Govt Total Registration<sup>4</sup></td>
          <td>{prices.TotalRegistration}</td>
        </tr>
        <tr>
          <td>{state} State Govt Stamp Duty</td>
          <td>{prices.StampDuty}</td>
        </tr>
        {showMRDP && <tr className="sep">
          <td><b>Manufacturer's Recommended Drive Away Price<sup>1</sup></b></td>
          <td><b>{prices.VehicleDriveAwayPrice}</b></td>
        </tr>}
      </tbody>
    </table>
  );
}

export default PriceTable;

export const PriceDropdown = ({ prices, state }) => {
  return (
    <Accordion flush className="py-3">
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <span><b>{prices.VehicleDriveAwayPrice}</b> Manufacturer's Recommended Drive Away Price<sup>1</sup></span>
        </Accordion.Header>
        <Accordion.Body className="px-0 py-3">
          <PriceTable prices={prices} state={state} />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export const PriceModal = ({ prices, state }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <Button variant="text" className="py-0" onClick={()=>{ setShow(true); }}><b>{prices.VehicleDriveAwayPrice}</b> Manufacturer's Recommended Drive Away Price<sup>1</sup></Button>
      <Modal isActive={show} variant="layer" closeButton close={()=>{ setShow(false); }}>
        <PriceTable prices={prices} state={state} showMRDP />
      </Modal>
    </>
  );
}
