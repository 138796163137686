import React, { useState, useEffect, useCallback, useRef } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import IconBack from '../audi-ui-components/icons/Back';
import IconForward from '../audi-ui-components/icons/Forward';
import Spinner from '../audi-ui-components/Spinner';
import API from '../functions/api';

const views = [
  // "myaun1t", // 3/4 front
  // "myaun8t", // side
  // "myaun6t", // 3/4 rear
  // "myaun2t", // headlight
  "n3c01aut", // 3/4 front
  "n3c14aut", // side
  "n3c12aut", // 3/4 rear
  "n3c11aut", // rear
  "n3c03aut", // front
];

const Thumb = ({ selected, slide, onClick }) => {
  return (
    <div
      className={'embla-thumbs__slide'.concat(
        selected ? ' embla-thumbs__slide--selected' : ''
      )}
    >
      <button
        onClick={onClick}
        className="embla-thumbs__slide__button"
        type="button"
      >
        <span className={`embla__slide__img ${slide.view}`} style={{backgroundImage: `url("${slide.imageUrl}")`}}></span>
      </button>
    </div>
  )
}

const HeroCarousel = ({
  stndRenderCodes,
  my = "2025",
  variant,
  driveType,
  extColour,
  exterior,
  interior,
  options,
  selectedOptions,
  setPayloadImg
}) => {
  const [slides, setSlides] = useState([{},{}]);
  const [loading, setLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [emblaMainRef, emblaMainApi] = useEmblaCarousel({});
  const [emblaThumbsRef, emblaThumbsApi] = useEmblaCarousel({
    containScroll: 'keepSnaps',
    dragFree: true
  });

  const onThumbClick = useCallback(
    (index) => {
      if (!emblaMainApi || !emblaThumbsApi) return;
      emblaMainApi.scrollTo(index);
    },
    [emblaMainApi, emblaThumbsApi]
  );

  const onSelect = useCallback(() => {
    if (!emblaMainApi || !emblaThumbsApi) return;
    setSelectedIndex(emblaMainApi.selectedScrollSnap());
    emblaThumbsApi.scrollTo(emblaMainApi.selectedScrollSnap());
  }, [emblaMainApi, emblaThumbsApi, setSelectedIndex]);
  
  const scrollPrev = useCallback(
    () => emblaMainApi && emblaMainApi.scrollPrev(),
    [emblaMainApi]
  );
  const scrollNext = useCallback(
    () => emblaMainApi && emblaMainApi.scrollNext(),
    [emblaMainApi]
  );

  useEffect(() => {
    if (!emblaMainApi) return;
    onSelect();
    emblaMainApi.on('select', onSelect);
    emblaMainApi.on('reInit', onSelect);
  }, [emblaMainApi, onSelect]);
  
  const controllerRef = useRef(null);
  
  const getImage = (view, opts, signal) => {
    return new Promise((resolve, reject) => {
      return API.post(
        `${process.env.RAZZLE_MEDIASERVICE}/MediaServiceCache/modelimageurl`,
        {
          "modelCode": variant + driveType,
          "modelYear": my,
          "exteriorColor": extColour,
          "interiorColor": interior,
          "optionalEquipments": opts,
          "standardEquipments": stndRenderCodes[variant + driveType],
          "view": view,
          "mimeType": "image/png",
          "width": 800
        },
        {signal: signal}
      ).then(res => {
        resolve(res.data);
      }).catch(() => {
        resolve({error: "Could not load image"});
      });
    });
  };
  
  useEffect(() => {
    // console.log("getting images");
    if (controllerRef.current !== null) {controllerRef.current.abort();}
    controllerRef.current = new AbortController();
    let opts = [];
    if (selectedOptions.length > 0) {
      for (let i=0; i<selectedOptions.length; i++) {
        let optObj = options[selectedOptions[i]];
        let codes = optObj.codes;
        if (typeof codes !== "string") {
          codes = optObj.codes[variant + driveType];
        }
        codes = codes.replaceAll(" ", "");
        opts = opts.concat(codes.split(","));
      }
    }
    if (exterior?.extra?.codes) {
      opts = opts.concat(exterior.extra.codes.split(","));
    }
    let images = views.map(view => {
      return getImage(view, opts, controllerRef.current.signal);
    });
    setLoading(true);
    Promise.all(images).then(result => {
      // console.log(result);
      setSlides(result);
      setLoading(false);
      if (result[0].imageUrl) {
        setPayloadImg(result[0].imageUrl);
      } else {
        setPayloadImg("");
      }
    }).catch(() => {
      setSlides([{error: "An error has occured"}]);
      setPayloadImg("");
      setLoading(false);
    });
  }, [variant, driveType, extColour, interior, selectedOptions]);

  return (
    <div className="embla mb-5 app-outdent position-relative">
      {loading && <div className="spinner-wrapper"><Spinner isContinuous /></div>}
      <div className="embla__viewport" ref={emblaMainRef}>
        <div className="embla__container">
          {slides.map((slide,i) => (
            <div className="embla__slide" key={`slide${i}`}>
              <span className={`embla__slide__img ${slide.view}`} style={{backgroundImage: `url("${slide.imageUrl}")`}}></span>
              {slide.error && <span className="aui-color-text-red position-absolute text-center d-block p-5" style={{top:"50%",left:"50%",transform:"translate(-50%,-50%)"}}>{slide.error}</span>}
            </div>
          ))}
        </div>
        <div className="embla__buttons">
          <button type="button" onClick={scrollPrev} disabled={!emblaMainApi?.canScrollPrev()}><IconBack large /></button>
          <button type="button" onClick={scrollNext} disabled={!emblaMainApi?.canScrollNext()}><IconForward large /></button>
        </div>
      </div>
      
      <div className="embla-thumbs__wrapper d-none d-medium-block">
        <div className="embla-thumbs">
          <div className="embla-thumbs__viewport" ref={emblaThumbsRef}>
            <div className="embla-thumbs__container">
              {slides.map((slide, ti) => (
                <Thumb
                  onClick={() => onThumbClick(ti)}
                  selected={ti === selectedIndex}
                  slide={slide}
                  key={`thumb${ti}`}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="embla__buttons">
          <button type="button" onClick={scrollPrev} disabled={!emblaMainApi?.canScrollPrev()}><IconBack large /></button>
          <button type="button" onClick={scrollNext} disabled={!emblaMainApi?.canScrollNext()}><IconForward large /></button>
        </div>
      </div>
    </div>
  )
}

export default HeroCarousel
