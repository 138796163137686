import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AudiHeader from './components/audi/AudiHeader';
import AudiFooter from './components/audi/AudiFooter';
import Reservation from './pages/Reservation';

import './styles/main.scss';

const App = () => {

  return(
    <div id="App">
      <Switch>
        <Route path="/ipad/q4-etron" render={(routeProps) => <Reservation {...routeProps} isIpad />} />
        <Route path="/q4-etron" render={(routeProps) => <>
          <Helmet>
            <title>Q4 e-tron pre-configuration form</title>
            <meta property="og:title" content="Q4 e-tron pre-configuration form" />
            <meta property="og:description" content="Simply build your ideal Q4 e-tron using the configurator below, provide your details in the form, and your preferred dealer will contact you to discuss your next steps." />
            <meta property="og:url" content="https://reservation.audi.com.au/q4-etron" />
          </Helmet>
          <div className="app-outer">
            <AudiHeader api={process.env.RAZZLE_MYAUDI_CMS} />
          </div>
          <main>
            <Reservation {...routeProps} />
          </main>
          <AudiFooter api={process.env.RAZZLE_MYAUDI_CMS} />
        </>} />
        <Route path="*" render={({ staticContext }) => {
          if (staticContext) { staticContext.status = 404; }
          return <div>404 Not Found</div>
        }} />
      </Switch>
    </div>
  );
};

export default App;
