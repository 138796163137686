import React from 'react';

import TextField from '../audi-ui-components/TextField';
// import TextArea from '@audi-au/aui-react-components/lib/TextArea';
import Select from '../audi-ui-components/Select';
// import Dropdown from '@audi-au/aui-react-components/lib/Dropdown';
import Checkbox from '../audi-ui-components/Checkbox';
// import Switch from '@audi-au/aui-react-components/lib/Switch';
// import RadioFieldset from '@audi-au/aui-react-components/lib/RadioFieldset';
// import DateField from './DateField';
// import DealerSelect from './DealerSelect';

const fieldComponents = {
  "text": TextField,
  "checkbox": Checkbox,
  // [FIELD_TEXTAREA]: TextArea,
  // [FIELD_RADIOS]: RadioFieldset,
  // [FIELD_SWITCH]: Switch,
  // [FIELD_DATEPICKER]: DateField,
  "select": Select,
  // [FIELD_DEALER_SELECT]: DealerSelect,
  // [FIELD_DROPDOWN]: Dropdown,
};

const FormField = (props) => {

  const {formikProps, type, name, onChange, formatValue, trim, wrapperClassName, showErrors, ...rest} = props;
  const {values, errors, touched, submitCount, setFieldValue, setFieldTouched} = formikProps;

  // allow errors to be a string or array
  let errMsg = "";
  if (errors[name]) {
    if (typeof errors[name] === 'string') {
      errMsg = errors[name];
    } else if (Array.isArray(errors[name])) {
      errMsg = errors[name][0];
    }
  }

  if (type === "checkbox") {
    return (
      <div className={`form-field ${wrapperClassName}`}>
        <Checkbox
          name={name}
          value=""
          checked={!!values[name]}
          onBlur={() => { setFieldTouched(name); }}
          onChange={(v,e) => { setFieldValue(name, e.target.checked); onChange(v); }}
          isInvalid={Boolean(errors[name] && (touched[name] || submitCount > 0 || showErrors))}
          invalidMessage={errMsg}
          {...rest}
        />
      </div>
    );
  }

  const Component = fieldComponents[type];
  return (
    <div className={`form-field ${wrapperClassName}`}>
      <Component
        name={name}
        value={values[name] || ""}
        onBlur={() => { setFieldTouched(name); }}
        onChange={(v) => {
          if (typeof formatValue === 'function') {
            v = formatValue(v);
          } else if (trim) {
            v = v.trim();
          }
          setFieldValue(name, v);
          onChange(v);
        }}
        isInvalid={Boolean(errors[name] && (touched[name] || submitCount > 0 || showErrors))}
        invalidMessage={errMsg}
        {...rest}
      />
    </div>
  );
}

FormField.defaultProps = {
  onChange: () => {},
  wrapperClassName: "mb-3",
  type: "text"
}

export default FormField;
